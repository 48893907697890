import React from "react";
import { Link } from "react-router-dom";

// Akses logo dari URL file yang diupload
const telkomselLogo = "/logo-telkomsel.png";

const Sidebar = () => {
  return (
    <div className="w-64 h-screen bg-red-600 text-white flex flex-col items-center fixed">
      <div className="p-6 flex flex-col items-center">
        {/* <img src={telkomselLogo} alt="Telkomsel Logo" className="w-44 mb-4" /> */}
        <h1 className="text-2xl font-bold mb-8">AI Smart Bot</h1>
      </div>
      <ul className="flex flex-col w-full">
        <li className="mb-4 w-full text-center">
          <Link to="/" className="text-lg hover:bg-red-500 block py-2">
            Chatbot
          </Link>
        </li>
        <li className="mb-4 w-full text-center">
          <Link to="/summarize" className="text-lg hover:bg-red-500 block py-2">
            Summarize
          </Link>
        </li>
        <li className="mb-4 w-full text-center">
          <Link to="/knowledge" className="text-lg hover:bg-red-500 block py-2">
            Knowledge
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
