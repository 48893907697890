import axios from "axios";

const HOST = process.env.REACT_APP_API_HOST || "http://3.26.59.125:5000";

// Static phone_number dan user_name
const phone_number = "082238366883";

// API untuk menjalankan chatbot
export const runBot = async (query) => {
  return axios.post(`${HOST}/api/bot/run`, {
    phone_number,
    query,
  });
};

// API untuk mendapatkan percakapan terbaru
export const getLatestConversation = async () => {
  const response = await axios.get(`${HOST}/api/bot/memory/latest`, {
    params: {
      phone_number: phone_number,
    },
  });

  if (response.data.status_code !== "BOT-000") {
    throw new Error(
      response.data.message || "Error fetching latest conversation."
    );
  }

  return response.data.data;
};

// Fungsi untuk memanggil API get memory
export const getMemoryData = async ({
  limit,
  offset,
  phoneNumber,
  summary,
}) => {
  const params = {
    limit,
    offset,
  };

  if (phoneNumber) {
    params.phone_number = phoneNumber;
  }

  if (summary) {
    params.summary = summary;
  }

  const response = await axios.get(`${HOST}/api/bot/memory`, { params });
  return response;
};

export const summarizeMemory = async (memory_id) => {
  return axios.post(`${HOST}/api/bot/summarize`, {
    phone_number: phone_number,
    memory_id: memory_id,
  });
};

export const getMemoryDetails = async (memory_id) => {
  try {
    const response = await axios.get(`${HOST}/api/bot/memory/${memory_id}`);

    // Cek apakah `api_response` ada dan apakah itu string
    if (response.data && response.data.data.api_response) {
      try {
        // Konversi `api_response` dari string ke JSON
        response.data.data.api_response = JSON.parse(
          response.data.data.api_response
        );
      } catch (error) {
        console.error("Failed to parse api_response:", error);
      }
    }

    return response;
  } catch (error) {
    console.error("Error fetching memory details:", error);
    throw error; // lempar kesalahan agar bisa ditangani di tempat lain jika perlu
  }
};

export const deleteMemory = async (memory_id) => {
  return axios.delete(`${HOST}/api/bot/memory/${memory_id}`);
};

// API untuk memulai percakapan baru dan mengosongkan redis
export const newConversation = async () => {
  return axios.post(`${HOST}/api/bot/new-conversation`, {
    phone_number,
  });
};

// API untuk mendapatkan percakapan terbaru dari redis
export const getLatestMemory = async () => {
  return axios.get(`${HOST}/api/bot/redis/memory`, {
    params: {
      phone_number,
    },
  });
};

// Fungsi untuk memanggil API get knowledge
export const getKnowledgeData = async ({ limit, offset }) => {
  const params = {
    limit,
    offset,
  };

  const response = await axios.get(`${HOST}/api/bot/knowledge`, { params });
  return response;
};

// Fungsi untuk delete knowledge berdasarkan URL
export const deleteKnowledge = async (fileUrl) => {
  return axios.delete(`${HOST}/api/bot/knowledge`, {
    params: {
      url: fileUrl,
    },
  });
};

export const uploadKnowledge = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${HOST}/api/bot/knowledge`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
