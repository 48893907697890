import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Untuk mendapatkan memory_id dari URL
import { getMemoryDetails } from "../api/api"; // API untuk mendapatkan detail memory
import { FaUser, FaRobot } from "react-icons/fa"; // Icon untuk user dan bot

const MemoryDetails = () => {
  const { memory_id } = useParams(); // Ambil memory_id dari URL
  const [memoryDetails, setMemoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch detail memory dari API
    const fetchMemoryDetails = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await getMemoryDetails(memory_id); // Panggil API
        setMemoryDetails(response.data.data); // Set detail memory ke state
      } catch (error) {
        setError("Failed to fetch memory details");
      } finally {
        setLoading(false);
      }
    };

    fetchMemoryDetails();
  }, [memory_id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!memoryDetails) {
    return <p>No memory details found.</p>;
  }

  // Dapatkan data api_response yang sudah di-parse di API
  const apiResponse = memoryDetails.api_response;

  // Fungsi untuk menampilkan informasi berdasarkan status_code
  const renderApiResponse = () => {
    if (!apiResponse) {
      return <p>API response is missing or null.</p>;
    }

    switch (apiResponse.status_code) {
      case "BOT-000":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
            <p>
              <strong>User Name:</strong> {apiResponse.user_name || "N/A"}
            </p>
          </div>
        );
      case "BOT-002":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
            <p>
              <strong>Store Name:</strong> {apiResponse.store_name || "N/A"}
            </p>
            <p>
              <strong>Visit Time:</strong> {apiResponse.visit_time || "N/A"}
            </p>
            <p>
              <strong>User Name:</strong> {apiResponse.user_name || "N/A"}
            </p>
          </div>
        );
      case "BOT-003":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
            <p>
              <strong>Recall Time:</strong> {apiResponse.recall_time || "N/A"}
            </p>
            <p>
              <strong>User Name:</strong> {apiResponse.user_name || "N/A"}
            </p>
          </div>
        );
      case "BOT-004":
        return (
          <div>
            <p>
              <strong>Status Code:</strong> {apiResponse.status_code}
            </p>
          </div>
        );
      default:
        return <p>No valid status code found in API response.</p>;
    }
  };

  return (
    <div className="p-6 bg-white h-screen flex">
      {/* Container kiri untuk Basic Information dan API Response */}
      <div className="w-1/2 pr-4">
        <h1 className="text-2xl font-bold mb-4">Memory Details</h1>

        {/* Tampilkan detail memory */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Basic Information</h2>
          <p>
            <strong>Memory ID:</strong> {memoryDetails.memory_id}
          </p>
          <p>
            <strong>Created At:</strong> {memoryDetails.created_at}
          </p>
          <p>
            <strong>Phone Number:</strong> {memoryDetails.phone_number}
          </p>
          <p>
            <strong>Summarize:</strong> {memoryDetails.summarize || "N/A"}
          </p>
        </div>
      </div>

      {/* Container kanan untuk percakapan seperti tampilan HP */}
      <div className="w-1/2 max-w-md mx-auto p-4 border rounded-lg shadow-lg bg-white flex flex-col h-full">
        <div className="flex-grow overflow-y-auto">
          {memoryDetails.conversations.map((conv, index) => (
            <div key={index}>
              {/* Pesan user */}
              {conv.user_message && (
                <div className="flex justify-end items-start space-x-2 mb-2">
                  <div className="bg-blue-500 text-white p-3 rounded-lg max-w-xs text-sm">
                    <p>{conv.user_message}</p>
                  </div>
                  <FaUser className="text-blue-500 text-lg" />
                </div>
              )}

              {/* Pesan bot */}
              {conv.bot_response && (
                <div className="flex justify-start items-start space-x-2 mb-2">
                  <FaRobot className="text-gray-500 text-lg" />
                  <div className="bg-gray-200 p-3 rounded-lg max-w-xs text-sm">
                    <p>{conv.bot_response}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MemoryDetails;
