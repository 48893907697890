import React, { useState, useEffect } from "react";
import { runBot, newConversation, getLatestConversation } from "../api/api";
import { FaSpinner, FaUser, FaRobot } from "react-icons/fa";

const Chatbot = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]); // Menyimpan history percakapan
  const [errorMessage, setErrorMessage] = useState(""); // Menyimpan pesan error
  const [loadingNewConversation, setLoadingNewConversation] = useState(false); // State untuk tombol New Conversation

  // Fungsi untuk mendapatkan percakapan terbaru saat halaman pertama kali dibuka
  useEffect(() => {
    const fetchLatestConversation = async () => {
      setLoading(true);
      setErrorMessage("");
      try {
        const latestConversation = await getLatestConversation();
        setConversationHistory(latestConversation.conversations);
      } catch (error) {
        setErrorMessage(
          error.message || "Error fetching the latest conversation."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLatestConversation();
  }, []);

  // Fungsi untuk memulai percakapan baru
  const handleNewConversation = async () => {
    setLoadingNewConversation(true);
    setErrorMessage("");
    try {
      await newConversation();
      setConversationHistory([]); // Kosongkan history percakapan di UI
    } catch (error) {
      setErrorMessage("Error starting new conversation.");
    } finally {
      setLoadingNewConversation(false);
    }
  };

  // Fungsi untuk menjalankan chatbot berdasarkan query user
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    try {
      // Tambahkan pertanyaan user ke history sebelum ada response dari API
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { user_message: query },
      ]);

      const result = await runBot(query);
      const botResponse = result.data.answer;
      const statusCode = result.data.status_code;

      if (statusCode === "BOT-999") {
        setErrorMessage(result.data.message || "Error running chatbot.");
      } else {
        // Tambahkan response bot ke history
        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { bot_response: botResponse },
        ]);
      }

      setQuery(""); // Kosongkan input setelah pengiriman
    } catch (error) {
      setErrorMessage("Error running chatbot.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="flex justify-between items-center p-4">
        <h1 className="text-2xl font-bold">AI Smart Chatbot</h1>
        {/* Button untuk memulai percakapan baru */}
        <button
          onClick={handleNewConversation}
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
          disabled={loadingNewConversation}
        >
          {loadingNewConversation ? (
            <FaSpinner className="animate-spin" />
          ) : (
            "New Conversation"
          )}
        </button>
      </div>

      {/* Render error message jika ada */}
      {errorMessage && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
          <p>{errorMessage}</p>
        </div>
      )}

      {/* Layout untuk percakapan */}
      <div className="flex-grow flex flex-col bg-gray-100 p-4 max-w-md mx-auto w-full h-full overflow-y-auto">
        {conversationHistory.map((message, index) => (
          <div key={index} className="mb-2">
            {/* Render user message */}
            {message.user_message && (
              <div className="flex items-center justify-end space-x-2">
                <div className="bg-blue-500 text-white p-3 rounded-lg max-w-xs text-sm">
                  <p>{message.user_message}</p>
                </div>
                <FaUser className="text-blue-500 text-lg" />
              </div>
            )}

            {/* Render bot message */}
            {message.bot_response && (
              <div className="flex items-center justify-start space-x-2">
                <FaRobot className="text-gray-500 text-lg" />
                <div className="bg-gray-200 p-3 rounded-lg max-w-xs text-sm">
                  <p>{message.bot_response}</p>
                </div>
              </div>
            )}
          </div>
        ))}
        {/* Tampilkan indikator loading hanya di bawah percakapan */}
        {loading && (
          <div className="flex justify-center mt-4">
            <FaSpinner className="animate-spin text-gray-500 text-xl" />
          </div>
        )}
      </div>

      {/* Form untuk query */}
      <form onSubmit={handleSubmit} className="flex p-4 bg-white mb-3">
        <textarea
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
          className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-red-500 text-base"
          required
          disabled={loading} // Disabled saat sedang loading
        />
        <button
          type="submit"
          className="bg-red-600 text-white py-3 px-6 ml-2 rounded hover:bg-red-700 flex justify-center items-center"
          disabled={loading} // Disabled saat sedang loading
        >
          {loading ? <FaSpinner className="animate-spin" /> : "Send"}
        </button>
      </form>
    </div>
  );
};

export default Chatbot;
