import React, { useState, useEffect } from "react";
import { getMemoryData, deleteMemory, summarizeMemory } from "../api/api";
import { FaTrash, FaEye, FaFileAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Gunakan useNavigate

const Summarize = () => {
  const [memories, setMemories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(10); // Batas per halaman
  const [offset, setOffset] = useState(0); // Offset untuk pagination
  const [totalCount, setTotalCount] = useState(0); // Total data
  const [showConfirm, setShowConfirm] = useState(false); // State untuk modal konfirmasi
  const [confirmLoading, setConfirmLoading] = useState(false); // State untuk loading konfirmasi
  const [confirmAction, setConfirmAction] = useState(null); // Fungsi yang akan dijalankan saat konfirmasi
  const [selectedMemoryId, setSelectedMemoryId] = useState(null); // Memory ID yang dipilih untuk tindakan

  const navigate = useNavigate(); // Menggunakan useNavigate untuk navigasi

  useEffect(() => {
    fetchMemoryData();
  }, [limit, offset]);

  const fetchMemoryData = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await getMemoryData({ limit, offset });
      setMemories(response.data.data);
      setTotalCount(response.data.total_count);
    } catch (error) {
      setError("Failed to fetch memory data");
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk menangani pagination
  const handleNext = () => {
    if (offset + limit < totalCount) {
      setOffset(offset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  // Fungsi untuk menghapus memory
  const handleDelete = async (memory_id) => {
    setConfirmLoading(true); // Menampilkan loading di dalam konfirmasi
    try {
      await deleteMemory(memory_id); // Menghapus memory dari API
      fetchMemoryData(); // Refresh data setelah dihapus
    } catch (error) {
      setError("Failed to delete memory");
    } finally {
      setConfirmLoading(false); // Menghentikan loading setelah selesai
      setShowConfirm(false); // Menutup modal setelah tindakan selesai
    }
  };

  // Fungsi untuk men-summarize memory
  const handleSummarize = async (memory_id) => {
    setConfirmLoading(true); // Menampilkan loading di dalam konfirmasi
    try {
      await summarizeMemory(memory_id); // Memanggil API untuk summarize
      fetchMemoryData(); // Refresh data setelah summarize
    } catch (error) {
      setError("Failed to summarize memory");
    } finally {
      setConfirmLoading(false); // Menghentikan loading setelah selesai
      setShowConfirm(false); // Menutup modal setelah tindakan selesai
    }
  };

  // Fungsi untuk melihat detail memory
  const handleDetail = (memory_id) => {
    navigate(`/memory/${memory_id}`); // Menggunakan useNavigate untuk redirect
  };

  // Fungsi untuk menampilkan modal konfirmasi
  const showConfirmation = (action, memory_id) => {
    setShowConfirm(true); // Tampilkan modal
    setConfirmAction(() => action); // Simpan fungsi yang akan dijalankan saat konfirmasi
    setSelectedMemoryId(memory_id); // Simpan memory_id yang dipilih
  };

  // Fungsi untuk menangani konfirmasi tindakan
  const handleConfirm = async (confirm) => {
    if (confirm && confirmAction && selectedMemoryId) {
      await confirmAction(selectedMemoryId); // Jalankan fungsi jika user konfirmasi
    }
    // Apapun pilihannya (Yes/No), tutup modal konfirmasi
    setShowConfirm(false);
    setConfirmAction(null);
    setSelectedMemoryId(null);
  };

  return (
    <div className="p-6 bg-white">
      <h1 className="text-2xl font-bold mb-4">Memory Summarize</h1>

      {/* Tabel Data */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          {/* Membungkus tabel dengan div agar hanya tabel yang dapat di-scroll */}
          <div className="overflow-y-auto max-h-96">
            <table className="min-w-full table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border p-2">Memory ID</th>
                  <th className="border p-2">Created At</th>
                  <th className="border p-2">Phone Number</th>
                  <th className="border p-2">Summary</th>
                  <th className="border p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {memories.map((memory) => (
                  <tr key={memory.memory_id}>
                    <td className="border p-2">{memory.memory_id}</td>
                    <td className="border p-2">{memory.created_at}</td>
                    <td className="border p-2">{memory.phone_number}</td>
                    <td className="border p-2">{memory.summarize || "N/A"}</td>
                    <td className="border p-2">
                      {/* Tombol untuk Summarize */}
                      <button
                        onClick={() =>
                          showConfirmation(handleSummarize, memory.memory_id)
                        }
                        className="text-blue-500 mr-2"
                      >
                        <FaFileAlt />
                      </button>
                      {/* Tombol untuk Detail */}
                      <button
                        onClick={() => handleDetail(memory.memory_id)}
                        className="text-green-500 mr-2"
                      >
                        <FaEye />
                      </button>
                      {/* Tombol untuk Delete */}
                      <button
                        onClick={() =>
                          showConfirmation(handleDelete, memory.memory_id)
                        }
                        className="text-red-500"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrevious}
              disabled={offset === 0}
              className="bg-gray-300 py-2 px-4 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={offset + limit >= totalCount}
              className="bg-gray-300 py-2 px-4 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {/* Modal Konfirmasi */}
      {showConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>
              {confirmLoading
                ? "Processing..."
                : "Are you sure you want to proceed?"}
            </p>
            <div className="flex justify-center mt-4 ">
              <button
                onClick={() => handleConfirm(true)}
                className="bg-green-600 py-2 px-4 mr-2 rounded"
                disabled={confirmLoading} // Disabled saat loading
              >
                {confirmLoading ? "Loading..." : "Yes"}
              </button>
              <button
                onClick={() => handleConfirm(false)}
                className="bg-red-600 text-white py-2 px-4 rounded"
                disabled={confirmLoading} // Disabled saat loading
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Summarize;
