import React, { useState, useEffect } from "react";
import { FaTrash, FaUpload } from "react-icons/fa"; // Icons for Delete and Upload
import { getKnowledgeData, deleteKnowledge, uploadKnowledge } from "../api/api"; // Import all API functions

const Knowledge = () => {
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [loading, setLoading] = useState(false); // Used for both loading and uploading
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(10); // Limit per page
  const [offset, setOffset] = useState(0); // Offset for pagination
  const [totalCount, setTotalCount] = useState(0); // Total count of data
  const [showConfirm, setShowConfirm] = useState(false); // State for confirmation modal
  const [confirmLoading, setConfirmLoading] = useState(false); // Loading state for confirmation
  const [confirmAction, setConfirmAction] = useState(null); // Function to execute on confirm
  const [selectedFileUrl, setSelectedFileUrl] = useState(null); // Store the file URL to delete
  const [showUploadModal, setShowUploadModal] = useState(false); // State to show upload modal
  const [file, setFile] = useState(null); // Store selected file for upload

  useEffect(() => {
    fetchKnowledgeData();
  }, [limit, offset]);

  // Function to fetch knowledge data
  const fetchKnowledgeData = async () => {
    setLoading(true); // Start loading
    setError("");
    try {
      const response = await getKnowledgeData({ limit, offset }); // Fetch knowledge data from API
      setKnowledgeData(response.data.data);
      setTotalCount(response.data.total_count);
    } catch (error) {
      setError("Failed to fetch knowledge data");
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  // Function to handle pagination next
  const handleNext = () => {
    if (offset + limit < totalCount) {
      setOffset(offset + limit);
    }
  };

  // Function to handle pagination previous
  const handlePrevious = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  // Function to delete knowledge
  const handleDelete = async (fileUrl) => {
    setConfirmLoading(true); // Show loading while deleting
    try {
      const response = await deleteKnowledge(fileUrl); // Delete API call
      if (response.data.status_code !== "BOT-000") {
        setError("Error deleting knowledge");
      } else {
        fetchKnowledgeData(); // Refresh data after successful deletion
      }
    } catch (error) {
      setError("Failed to delete knowledge");
    } finally {
      setConfirmLoading(false); // Stop loading after completion
      setShowConfirm(false); // Hide confirmation modal
    }
  };

  // Show confirmation modal before deleting
  const showConfirmation = (action, fileUrl) => {
    setShowConfirm(true);
    setConfirmAction(() => () => action(fileUrl)); // Set action for confirmation
    setSelectedFileUrl(fileUrl); // Store file URL to be deleted
  };

  // Handle confirm action
  const handleConfirm = async (confirm) => {
    if (confirm && confirmAction && selectedFileUrl) {
      await confirmAction(selectedFileUrl); // Run the delete action if confirmed
    }
    setShowConfirm(false); // Close modal either way
    setConfirmAction(null);
    setSelectedFileUrl(null);
  };

  // Function to handle file upload
  const handleFileUpload = async () => {
    if (!file) {
      setError("Please select a file");
      return;
    }

    const allowedTypes = [
      "application/pdf", // .pdf
      "text/csv", // .csv
      "application/vnd.ms-excel", // .xls
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    ];
    if (!allowedTypes.includes(file.type)) {
      setError("Only PDF, Excel, and CSV files are allowed.");
      return;
    }

    setLoading(true); // Show loading while uploading
    try {
      const response = await uploadKnowledge(file); // Call upload API

      if (response.data.status_code !== "BOT-000") {
        setError("Error uploading file");
      } else {
        fetchKnowledgeData(); // Refresh data after successful upload
        setShowUploadModal(false); // Close modal
        setFile(null); // Reset file input
      }
    } catch (error) {
      setError("Failed to upload file.");
    } finally {
      setLoading(false); // Stop loading after upload
    }
  };

  return (
    <div className="p-6 bg-white">
      <h1 className="text-2xl font-bold mb-4">Knowledge Management</h1>

      {/* Button to upload knowledge */}
      <button
        onClick={() => setShowUploadModal(true)}
        className="bg-blue-500 text-white py-2 px-4 rounded mb-4 hover:bg-blue-600 flex items-center"
        disabled={loading} // Disable while loading
      >
        {loading ? (
          "Uploading..."
        ) : (
          <>
            <FaUpload className="mr-2" /> Upload Knowledge
          </>
        )}
      </button>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr>
                <th className="border p-2">ID</th>
                <th className="border p-2">Created At</th>
                <th className="border p-2">File Name</th>
                <th className="border p-2">File Type</th>
                <th className="border p-2">File URL</th>
                <th className="border p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {knowledgeData.map((knowledge) => (
                <tr key={knowledge.id}>
                  <td className="border p-2">{knowledge.id}</td>
                  <td className="border p-2">{knowledge.created_at}</td>
                  <td className="border p-2">{knowledge.file_name}</td>
                  <td className="border p-2">{knowledge.file_type}</td>
                  <td className="border p-2">
                    <a
                      href={knowledge.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500"
                    >
                      View File
                    </a>
                  </td>
                  <td className="border p-2">
                    {/* Delete button */}
                    <button
                      onClick={() =>
                        showConfirmation(handleDelete, knowledge.file_url)
                      }
                      className="text-red-500"
                      disabled={confirmLoading} // Disable while deleting
                    >
                      {confirmLoading ? "Deleting..." : <FaTrash />}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevious}
          disabled={offset === 0 || loading} // Disable during loading
          className="bg-gray-300 py-2 px-4 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={offset + limit >= totalCount || loading} // Disable during loading
          className="bg-gray-300 py-2 px-4 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Confirmation Modal */}
      {showConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>
              {confirmLoading
                ? "Processing..."
                : "Are you sure you want to delete this knowledge?"}
            </p>
            <div className="flex justify-center mt-4">
              <button
                onClick={() => handleConfirm(true)}
                className="bg-green-600 py-2 px-4 mr-2 rounded"
                disabled={confirmLoading}
              >
                {confirmLoading ? "Loading..." : "Yes"}
              </button>
              <button
                onClick={() => handleConfirm(false)}
                className="bg-red-600 text-white py-2 px-4 rounded"
                disabled={confirmLoading}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Upload Modal */}
      {showUploadModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Upload Knowledge</h2>
            <input
              type="file"
              accept=".pdf, .csv, .xlsx, .xls"
              onChange={(e) => setFile(e.target.files[0])}
              className="block w-full mb-4 p-2 border"
              disabled={loading} // Disable input while uploading
            />
            <h4 className="text-xs -mt-4 font-semibold">
              File type only: PDF, CSV, XLSX, XLS
            </h4>
            <div className="flex justify-end">
              <button
                onClick={() => setShowUploadModal(false)}
                className="bg-gray-300 text-black py-2 px-4 mr-2 rounded"
                disabled={loading} // Disable button while uploading
              >
                Cancel
              </button>
              <button
                onClick={handleFileUpload}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                disabled={loading} // Disable button while uploading
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Knowledge;
